import Cteni from "./Cteni/Cteni.jsx";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Cteni />
    </div>
  );
}

export default App;
