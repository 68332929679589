import React, { useState, useEffect } from "react";

function Cteni() {
  // Declare a new state variable, which we'll call "count"
  const [count, setCount] = useState(-1);
  const [res, setRes] = useState([]);
  const [idx, setIdx] = useState(0);
  const [bcg, setBcg] = useState("white");
  const [txtcolor, setTxtcolor] = useState("white");
  const [seconds, setSeconds] = useState(0);
  const [startTime, setStartTime] = useState(0);
  const totalSeconds = 180;

  useEffect(() => {
    // load json file
    let str = require("./cteni1.json");

    // separate into words
    let res = str.text.split(" ");
    res = res.filter((word) => word.length > 3);

    setRes(res);
  }, []);

  useEffect(() => {
    setIdx(Math.floor(Math.random() * res.length));
  }, [count, res]);

  useEffect(() => {
    if (count >= 0) {
      if (seconds > totalSeconds) {
        setBcg("red");
        setTxtcolor("red");
      } else {
        setTxtcolor("black");
      }
      const timer = setInterval(() => {
        let curTime = new Date().getTime();
        setSeconds(Math.round((curTime - startTime) / 1000));
      }, 1000); // clearing interval
      return () => clearInterval(timer);
    }
  }, [count, bcg, txtcolor, seconds]);

  console.log(seconds);
  return (
    <div style={{ backgroundColor: bcg, color: txtcolor }}>
      <h1 style={{ margin: "150px", fontSize: 80 }}> {res[idx]}</h1>
      <p style={count === -1 ? { color: "white" } : { color: "black" }}>
        Počet slov : {count}
      </p>
      <button
        onClick={() => {
          if (count === -1) {
            setStartTime(new Date().getTime());
          }
          setCount(count + 1);
        }}
      >
        {count === -1 ? "Start" : "Další"}
      </button>
      <p style={{ color: txtcolor }}>{totalSeconds - seconds}</p>
    </div>
  );
}

export default Cteni;
